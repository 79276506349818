<template>
  <div class="overflow-hidden d-flex justify-content-center align-items-center">
    <div class="row px-4">
      <div class="col-md-4 auth-bg signup-image">
        <div class="d-flex ms-4 mt-4">
          <img
            :src="require('@/assets/logo-auth.png')"
            alt="logo"
            class="auth-logo me-2"
          />
          <h1 class="auth-logo-text">Propelond</h1>
        </div>
      </div>
      <div class="col-md-8 bg-white px-md-5 right-auth-card">
        <form @submit.prevent="registerNewUser">
          <h4 class="auth-text mb-3">Register</h4>
          <div class="row mb-3">
            <div class="col-md-6 my-2 my-lg-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control border-end-0"
                  required
                  autocomplete="first_name"
                  autofocus
                  placeholder="First Name"
                  v-model="first_name"
                />
                <label class="input-group-text">
                  <i class="bi bi-person-fill"></i>
                </label>
              </div>
            </div>
            <div class="col-md-6 my-2 my-lg-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control border-end-0"
                  required
                  autocomplete="last_name"
                  autofocus
                  placeholder="Last Name"
                  v-model="last_name"
                />
                <label class="input-group-text">
                  <i class="bi bi-person-fill"></i>
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3" id="user-email">
            <div class="col-md-12 my-2 my-lg-0">
              <div class="input-group">
                <input
                  id="email"
                  type="email"
                  class="form-control border-end-0"
                  required
                  autocomplete="email"
                  placeholder="E-Mail Address"
                  v-model="email"
                />
                <label class="input-group-text">
                  <i class="bi bi-envelope-fill"></i>
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6 mb-3 mb-md-0">
              <input
                type="text"
                placeholder="Date of birth - MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                class="form-control"
                required
                autocomplete="dob"
                autofocus
                v-model="dob"
              />
            </div>
            <div class="col-md-6">
              <select
                class="form-select"
                v-model="gender"
                aria-label="Gender select"
                required
              >
                <option selected value="">Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-12 mb-3">
              <div class="input-group">
                <input
                  type="password"
                  class="form-control border-end-0"
                  required
                  autocomplete="new-password"
                  placeholder="Password"
                  v-model="password"
                />
                <label class="input-group-text">
                  <i class="bi bi-lock"></i>
                </label>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="input-group">
                <input
                  ref="passConfirm"
                  type="password"
                  class="form-control border-end-0"
                  required
                  autocomplete="new-password"
                  placeholder="Confirm Password"
                  v-model="password_confirmation"
                  :oninput="checkPassConfirmation"
                />
                <label class="input-group-text">
                  <i class="bi bi-lock-fill"></i>
                </label>
              </div>
            </div>
          </div>
          <div v-if="status_type === 'error'" class="text-danger">
            {{ message }}
          </div>
          <p>
            By clicking Register, you are agreeing to our
            <router-link
              to="/legal/terms-of-service"
              class="text-decoration-none"
            >
              Terms of Service
            </router-link>
            and
            <router-link to="/legal/privacy" class="text-decoration-none">
              Privacy Policy
            </router-link>
          </p>
          <div class="mt-3 d-grid mb-3">
            <button type="submit" class="btn btn-auth px-5 text-white">
              Register
            </button>
          </div>
          <hr class="w-75 mx-auto" />
          <router-link
            to="/login"
            class="btn btn-link text-decoration-none text-center mb-2"
          >
            <span class="text-black">Already have an account?</span> Log In
          </router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      dob: "",
      gender: "",
      password: "",
      password_confirmation: ""
    }
  },
  methods: {
    ...mapActions("auth", ["signUp"]),
    registerNewUser() {
      const payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        dob: this.dob,
        gender: this.gender,
        password: this.password,
        password_confirmation: this.password_confirmation
      }

      this.signUp(payload)
    },
    checkPassConfirmation() {
      let passConfirm = this.$refs.passConfirm

      if (this.password !== this.password_confirmation) {
        let message = "Password confirmation must match the password"
        passConfirm.setCustomValidity(message)
      } else {
        passConfirm.setCustomValidity("")
      }
    }
  },
  computed: {
    ...mapState("status", ["status_type", "message"])
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/auth";

.form-control {
  height: 50px;
}

.form-select {
  height: 50px;
}

.btn-auth {
  height: 50px;
}
.logo-text {
  font-size: 5rem;
  font-weight: bold;
}
.login-intro {
  font-size: 0.8rem;
  opacity: 0%;
}
.login-intro__header {
  font-size: 1.5rem;
  opacity: 0%;
}
.logo {
  width: 10.8rem;
  opacity: 0;
}
.signup-image {
  background-image: url(../assets/img/signup-bg.png);
}
</style>
